export const createAuthService = (authRepository) => {
    const login = emailFromInput => authRepository.login(emailFromInput)

    const getUserInfo = (extended = false) => authRepository.getUserInfo(extended)

    const getAdministrators = () => authRepository.getAdministrators()

    const acceptTerms = (version = "1.0") => authRepository.acceptTerms(version)

    const createAdministrator = data => authRepository.createAdministrator(data)

    return {
        login,
        getUserInfo,
        getAdministrators,
        acceptTerms,
        createAdministrator
    }
}
