export const createUserService = (userRepository) => {
    const create = email => userRepository.create(email)
    const createBotUser = data => userRepository.createBotUser(data)
    const getActiveSubscription = () => userRepository.getActiveSubscription()
    const createSubscription = () => userRepository.createSubscription()
    const cancelSubscription = subscriptionId => userRepository.cancelSubscription(subscriptionId)
    const freezeSubscription = subscriptionId => userRepository.freezeSubscription(subscriptionId)
    const unfreezeSubscription = subscriptionId => userRepository.unfreezeSubscription(subscriptionId)
    const getAvailableSubscriptions = () => userRepository.getAvailableSubscriptions()
    const getAddresses = () => userRepository.getAddresses()
    const detachProxy = (address) => userRepository.detachProxy(address)
    const getExtensionConfiguration = () => userRepository.getExtensionConfiguration()
    const toggleClosing = (email, bookie, closing) => userRepository.toggleClosing(email, bookie, closing)

    const getUserBets = (email) => userRepository.getUserBets(email)
    const getBookieCredentials = (email, bookie) => userRepository.getBookieCredentials(email, bookie)
    const getBookies = (email, complexMode) => complexMode ? userRepository.getComplexBookies(email) : userRepository.getBookies(email)
    const getNotifications = () => userRepository.getNotifications()
    const getUsersBilling = () => userRepository.getUsersBilling()
    const getUsersUpgrade = () => userRepository.getUsersUpgrade()
    const addPayment = (email, data) => userRepository.addPayment(email, data)
    const transferCredit = (email, amount) => userRepository.transferCredit(email, amount)
    const confirmPayment = (email, paymentId) => userRepository.confirmPayment(email, paymentId)
    const addCorrection = (email, data) => userRepository.addCorrection(email, data)
    const refreshBalance = (email) => userRepository.refreshBalance(email)
    const upgradeUser = (email, version) => userRepository.upgradeUser(email, version)
    const addReferral = (email, referredBy) => userRepository.addReferral(email, referredBy)
    const changeProxyMode = (email, mode) => userRepository.changeProxyMode(email, mode)
    const changeTimingMode = (email, mode) => userRepository.changeTimingMode(email, mode)
    const disableEmail = email => userRepository.disableEmail(email)
    const enableEmail = email => userRepository.enableEmail(email)
    const setReadyStatus = (email, status) => userRepository.setReadyStatus(email, status)
    const toggleBookieStatus = (email, bookie, action) => userRepository.toggleBookieStatus(email, bookie, action)
    const setActivationDate = (email, name, startDate) => userRepository.setActivationDate(email, name, startDate)
    const markNotificationSeen = error => userRepository.markNotificationSeen(error)
    const bookieAction = (email, bookie, action) => userRepository.bookieAction(email, bookie, action)
    const settleBet = (email, bet) => userRepository.settleBet(email, bet)
    const addBookie = (obj) => userRepository.addBookie(obj)
    const getChapters = () => userRepository.getChapters();
    const getBotHome = () => userRepository.getBotHome();
    const getInvoices = () => userRepository.getInvoices();
    const getInvoice = (id) => userRepository.getInvoice(id);
    const navigateToArb = (arb) => userRepository.navigateToArb(arb)
    const executeArb = (arb) => userRepository.executeArb(arb)
    const executeValuebet = bet => userRepository.executeValuebet(bet)
    const redeemCoupon = couponCode => userRepository.redeemCoupon(couponCode)
    const redeemPoints = points => userRepository.redeemPoints(points)
    const getCoupons = () => userRepository.getCoupons()
    const updateTags = (email, tags) => userRepository.updateTags(email, tags)
    const queryUsers = (email) => userRepository.queryUsers(email)

    const removeUserFromAdministrator = (administratorEmail, email) => userRepository.removeUserFromAdministrator(administratorEmail, email)

    const addUserToAdministrator = (administratorEmail, email) => userRepository.addUserToAdministrator(administratorEmail, email)

    return {
        create,
        createBotUser,
        getActiveSubscription,
        createSubscription,
        cancelSubscription,
        freezeSubscription,
        unfreezeSubscription,
        getAvailableSubscriptions,
        getAddresses,
        detachProxy,
        getExtensionConfiguration,
        toggleClosing,
        getUserBets,
        getBookieCredentials,
        getBookies,
        getNotifications,
        getUsersBilling,
        getUsersUpgrade,
        addPayment,
        transferCredit,
        confirmPayment,
        addCorrection,
        refreshBalance,
        upgradeUser,
        addReferral,
        changeProxyMode,
        changeTimingMode,
        disableEmail,
        enableEmail,
        toggleBookieStatus,
        setActivationDate,
        markNotificationSeen,
        bookieAction,
        settleBet,
        addBookie,
        getChapters,
        getBotHome,
        setReadyStatus,
        getInvoices,
        getInvoice,
        navigateToArb,
        executeArb,
        executeValuebet,
        redeemCoupon,
        redeemPoints,
        getCoupons,
        updateTags,
        queryUsers,
        removeUserFromAdministrator,
        addUserToAdministrator
    }
}
