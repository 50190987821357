import axios from 'axios';
import queryString from 'query-string';
import config from './config';
import { decode } from 'jsonwebtoken'

export const fetch = ({ path, method, data, headers, throwError = false }) => axios({
  method,
  url: `${config.BASE_URL}/${path}`,
  headers: headers ? headers : {
      Authorization: localStorage.jwt || ''
  },
  data
})
.then(handlingResponse([200], `Error trying to ${path}`))
.catch(error => {
  if (throwError) {
    throw error.response ? error.response.data : error;
  }
  logError(error);
});

export const fetchBlob = ({ path, method = 'GET', data, headers}) => 
 axios(`${config.BASE_URL}/${path}`, {
  method,
  headers: headers ? headers : {
    Authorization: localStorage.jwt || ''
  },
  responseType: 'blob',
  data
})


export const handlingResponse = (successStatus = [200], errorMessage = 'Error') => ({ status, data }) => {
  if (!successStatus.includes(status)) {
    const error = new Error(errorMessage);
    error.statusCode = status;
    throw error;
  }
  return data;
};

export const logError = error => {
  // Add application Insight
  throw error;
};

export const getCodeFromURL = () => {
  const { token } = queryString.parse(window.location.search);
  return token;
};

export const getEmailFromURL = () => {
  const { email } = queryString.parse(window.location.search);
  return email;
};

export const checkIfTokenStillValid = history => {
  if (!localStorage.jwt && history) return history.push('/home');
  if (!localStorage.jwt && !history) return false;
  const decodedToken = decode(localStorage.jwt);
  if (!decodedToken) return false;
  if (decodedToken.exp * 1000 < Date.now() - 1000 && history) history.push('/home');
  if (decodedToken.exp * 1000 < Date.now() - 1000 && !history) return false;
  return true;
};

export const checkToken = () => {
  if (!localStorage.jwt) return undefined;
  const decodedToken = decode(localStorage.jwt);
  if (!decodedToken) return undefined;
  if (decodedToken.exp * 1000 < Date.now() - 1000) return undefined
  return decodedToken;
};

export const logout = history => {
  localStorage.removeItem('jwt');
  if (history) {
    history.push('/');
    window.location.reload();
  }
};