export const getCredit = user => {
    const { billing } = user;
    if (billing) {
        const { type, corrections = [], amount, internalRate = 30 } = billing;
        const validPayments = (billing.payments || []).filter(({ amount, type }) => amount && !['raspberry', 'freecredits'].includes(type) );
        const isRaspberryPaid = (billing.payments || []).some(({ type }) => type === 'raspberry');
        const lastChargedRate = validPayments[validPayments.length - 1] ? validPayments[validPayments.length - 1].rate : internalRate;
  
        const payments = validPayments.map(payment => ({ ...payment, prorated: payment.rate ? (payment.amount * (100 / (payment.rate - ((payment.internalDiscount || 0) + (payment.providerDiscount || 0))))) * (((user.billing.internalRate || 30) - (payment.internalDiscount || 0)) / 100) : 0  }));
  
        const toConfirm = payments.filter(({ status }) => status !== 1).reduce((t , payment) => t + payment.prorated, 0) ;
  
        const paid = payments.reduce((t, c) => t + c.prorated, 0);

        const grossPayments = payments.reduce((t, c) => t + c.amount, 0);

        const providerPaid = payments.reduce((t, c) => t + c.amount, 0)

        return { type, profit: billing.profit, toConfirm, paid, grossPayments, providerPaid, rate: lastChargedRate, amount: (amount || 0).toFixed(2), corrections, payments, isRaspberryPaid };
    }
    return undefined;
  }
  