import { fetch } from '../utils';

export const createAuthRepository = () => {
     const login = (emailFromInput) => fetch({
        method: 'post',
        path: 'arbs/login',
        data: {
            email: emailFromInput.toLowerCase().trim()
        }
    });

    const getUserInfo = extended => fetch({
        method: 'get',
        path: extended ? 'administrators/me?extended=true' : 'administrators/me',
        headers: {
            Authorization: localStorage.jwt
        }
    });

     const getAdministrators = () => fetch({
        method: 'get',
        path: 'administrators',
        headers: {
            Authorization: localStorage.jwt
        },
    });

    const createAdministrator = data => fetch({
        method: 'post',
        path: 'administrators',
        data
    });

    const acceptTerms = (version = "1.0") => fetch({
        method: 'put',
        path: 'administrators/tos',
        headers: {
            Authorization: localStorage.jwt
        },
        data: {
            version
        }
    });

    return {
        login,
        getUserInfo,
        getAdministrators,
        acceptTerms,
        createAdministrator
    }
}
